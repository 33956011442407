require('./movimenti');

$(function printRegistroIntermediazione() {

    $('#registro_print_confirm').on('click', function () {
        $.ajax({

            'url': $(this).data('route'),
            'type': 'POST',
            'headers': {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            'data': {
                'data_stampa': $('#data_stampa').val(),
            },
            'success': function (data) {
                if (data == 'ok') {
                    $('#printModal').modal('hide');
                    $('#movimenti-intermediazione-datatable').DataTable().ajax.reload();
                    $('#movimenti-carico-scarico-datatable').DataTable().ajax.reload();
                    alert('Sono stati stampati e aggiornati correttamente tutti i movimenti');
                } else {
                    $('#printModal-body').append('Si è verificato un errore nel salvataggio dei movimenti:' + data);
                    setTimeout(function () {
                        $('#printModal').modal('hide');
                    }, 3000);
                }
            },
            'error': function (error) {
                console.log(error);
            }
        });
    });
});

/**
 * Gestisce la scelta da parte dell'utente del numero di pagine bianche da stampare per i registri di intermediazione e carico/scarico
 */
 $(function () {
    const maxPrintablePages = 100;

    const pages_max = $('#pages-max');
    const pages = $('#pages');
    
    function setMaxPagesNumber(el) {
        var pagesToPrint = pages.val();
        return el.text(pagesToPrint);
    }
    
    $(function () {
        pages.val(1);
        setMaxPagesNumber(pages_max);
    });
    
    pages.on('click keyup', function () {
        if (pages.val() > maxPrintablePages) {
            alert('ATTENZIONE! Il numero massimo di pagine stampabili è ' + maxPrintablePages)
            pages.val(maxPrintablePages)
        }
        return setMaxPagesNumber(pages_max)
    });
})

/**
 * Gestisce la scelta da parte dell'utente della sede da stampare per i registri di intermediazione e carico/scarico
 */
$(function () {
    const sede = '#sede_scelta';
    var cap_comune_provincia = $('#cap_comune_provincia');
    var toponimo_indirizzo = $('#toponimo_indirizzo');
    var numero_civico = $('#numero_civico');

    cap_comune_provincia.val(null);
    toponimo_indirizzo.val(null);
    numero_civico.val(null);

    $(sede).on('change', function () {
        const sede_scelta = $(`${sede} option:selected`);
        var selectedSede = sede_scelta.val();
        var selectedComune = sede_scelta.data('comune');
        var selectedCap = sede_scelta.data('cap');
        var selectedProvincia = sede_scelta.data('provincia');
        var selectedToponimo = sede_scelta.data('toponimo');
        var selectedIndirizzo = sede_scelta.data('indirizzo');
        var selectedNumeroCivico = sede_scelta.data('numero_civico');
        var sede_scelta_hidden = $('#sede_scelta_hidden');
        var selectedCountry = sede_scelta.data('nazione');

        if (selectedSede == '') {
            cap_comune_provincia.val(null);
            toponimo_indirizzo.val(null);
            numero_civico.val(null);        
            return
        }
        
        if (selectedCountry == 'IT') {
            sede_scelta_hidden.val(`${selectedToponimo} ${selectedIndirizzo}, ${selectedNumeroCivico}. ${selectedComune}, ${selectedCap}(${selectedProvincia})`);
            cap_comune_provincia.val(`${selectedCap} - ${selectedComune}(${selectedProvincia})`);
        } else {
            sede_scelta_hidden.val(`${selectedToponimo} ${selectedIndirizzo}, ${selectedNumeroCivico} (${selectedCountry})`);
            cap_comune_provincia.val(`${selectedCountry}`);
        }

        toponimo_indirizzo.val(`${selectedToponimo} ${selectedIndirizzo}`);
        numero_civico.val(selectedNumeroCivico);
    })
})

/**
 * Chenges color to datatable drawn rows 
 */
 $(function colorFormularioData() {
    const table = $('#movimenti-formulario-datatable').DataTable();
    
    table.on('draw.dt', function() {
        $(this).find('tbody tr').addClass('alert-warning');
    });
});