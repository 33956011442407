const { checkQuantita } = require('./movimenti');

require('./movimenti');

/**
 * Opens  modal on load if validation fails
 */
$(function () {
    if ($('#validationErrorGiacenza').val() == 'modalOpened') {
        if($('#modalType').val() == 'create') {
            return $("#addGiacenza").modal("show");
        }

        url = $('#updateUrl').val();

        if(url.length > 0) {
            getScarichiAssociatiAjax(url)
        }
        return $('#editGiacenza').modal('show');
    }
});

/**
 * Populates giacenza edit modal
 */
$(function manageMovimentoModal() {
    var dataTables = ['#magazzino_entrate', '#magazzino_uscite'];

    dataTables.forEach(el =>  {
        var actions_btn_field = el + ' td:last-of-type';
        var table = $(el).DataTable();

        table.on('draw.dt', function() {
            //flex to allineate action buttons on entrate table if it has records
            if(table.data().any()) {
                // $(actions_btn_field).addClass('d-flex');
            }

            target = $('.modal-btn');
            target.closest('tr').addClass('alert-warning');

            if(el == '#magazzino_entrate') {
                return handleDataOnGiacenzaModal(target);
            }
            return;
        });
    });

    $('#editGiacenza').on('hidden.bs.modal', function() {
        $(this).removeClass('readonly');
    });
});

/**
 * Handles data and interaction of giacenza modal
 */
function handleDataOnGiacenzaModal(target) {
    target.on('click', function() {
        var url = $(this).data('url');
        var form_inputs = $('.form-edit-giacenza :input:not(button):not(#cer_descrizione_edit_modal)');
        var submission_btn = $('#editGiacenza button[type="submit"]');
        var alert = $('#associato_alert');

        if($(this).hasClass('show-movimento-btn')) {
            $('#editGiacenza').addClass('readonly');
            form_inputs.prop('disabled', true);
            submission_btn.hide();
            alert.hide()

        } else if($(this).hasClass('edit-movimento-btn')){
            $('#editGiacenza').removeClass('readonly');
            form_inputs.prop('disabled', false);
            submission_btn.show();

            if(($('#kg_edit_modal').val())) {

            }

            checkForScarichiAssociati(this);
        }

        return populateGiacenzaModal(url);
    });
}

function populateGiacenzaModal(url) {
    // TODO: show loader
    $.ajax(url)
    .done(function(res) {
        const form = $('#form-edit-giacenza');
        const cer_id_edit_modal = '#cer_id_edit_modal';

        //Imposto la rotta per l'update
        form.attr('action', res.update_url);

        $('#data_movimento_edit_modal').val(res.data_movimento);
        $('#annotazioni_edit_modal').val(res.annotazioni);
        $('#cer_id_edit_modal option').val()
        $('#kg_edit_modal').val(res.kg);
        $('#litri_edit_modal').val(res.litri);
        $('#metri_cubi_edit_modal').val(res.metri_cubi);
        $('#cer_id_edit_modal option[value="' + res.cer_id + '"]').prop('selected', true);
        $('#stato_fisico_edit_modal option[value="' + res.stato_fisico + '"]').prop('selected', true);


        $('#relevant_data_associazione').val(JSON.stringify({
            cer_id: String(res.cer_id),
            classi_pericolo: res.classi_pericolo,
            data_movimento: res.data_movimento,
            kg: String(res.kg),
            litri: String(res.litri ?? ''),
            metri_cubi: String(res.metri_cubi ?? ''),
        }))

        var target = $('#cer_descrizione_edit_modal')

        function setCerDesc(origin, target) {
            var descrizione = $(origin).find(':selected').data('descrizione');
            target.val(descrizione);
        }

        setCerDesc(cer_id_edit_modal, target);

        $(cer_id_edit_modal).on('change', function () {
            setCerDesc(this, target)
        });

        toggleRadioWithValue();

        function resetClassiPericolo() {
            $('#classi_pericolo_edit_modal').val([]);
            $('#classi_pericolo_edit_modal').trigger('change');
        }

        if(res.classi_pericolo != null) {
            $('#classi_pericolo_edit_modal').val(res.classi_pericolo);
            $('#classi_pericolo_edit_modal').trigger('change');
        } else {
            resetClassiPericolo();
        }
    })
    .fail(function(err) {
        alert(err);
    }).always(function () {
    // TODO: hide loader
    });
}

/**
 * Checks radio if target input has value in #editGiacenza modal
 */
function toggleRadioWithValue() {
    let unita_misura = $($('#editGiacenza input[name="unita_misura"]'));

    unita_misura.each(function() {
        let target = $(this).data('target');
        if(($(target).val()) != '') {
            $(this).prop('checked', true);
        } else {
            $(this).prop('checked', false);
        }
    });
}

/**
 * Checks for scarichi associati
 */
function checkForScarichiAssociati(target) {
    var url = $(target).data('scarichi');

    //Input useful to display alert div when BE validation fails
    $('#updateUrl').val(url);

    return getScarichiAssociatiAjax(url);
};

/**
 * Get scarichi associati to a carico
 */
function getScarichiAssociatiAjax(url) {
    var alert = $('#associato_alert');
    var scarichi_to_reopen = $('#scarichi_non_stampati');
    var scarichi_to_reopen_suffix = $('#scarichi_non_stampati_suffix');
    var scarichi_to_invalidate = $('#scarichi_stampati');
    var scarichi_to_invalidate_suffix = $('#scarichi_stampati_suffix');
    var stampato = $('#stampati');
    var non_stampato = $('#non_stampati');

    $.ajax(url)
    .done(function(res){
        if(res.is_associato) {
            alert.show();
            $('#isAssociato').val(1);

            var array_non_stamp = res.non_stampato;
            var array_stamp = res.stampato;

            if (array_non_stamp.length > 1) {
                array_non_stamp.join(', ');
            }

            if (array_stamp.length > 1) {
                array_stamp.join(', ');
            }

            if(array_non_stamp.length) {
                non_stampato.show();
                if(res.n_non_stampato == 1) {
                    var suffix = ' scarico associato';
                } else if (res.n_non_stampato > 1) {
                    var suffix = ' scarichi associati';
                }
                scarichi_to_reopen.text(res.n_non_stampato);
                scarichi_to_reopen.attr('data-bs-content', 'id scarico: ' + array_non_stamp)
                scarichi_to_reopen_suffix.text(suffix);

            } else {
                non_stampato.hide()
                scarichi_to_reopen.text('');
                scarichi_to_reopen.removeAttr('data-bs-content');
                scarichi_to_reopen_suffix.text('');
            }

            if(array_stamp.length) {
                stampato.show();
                if(res.n_stampato == 1) {
                    var suffix = ' scarico associato stampato';
                } else if (res.n_stampato > 1) {
                    var suffix = ' scarichi associati stampati';
                }
                scarichi_to_invalidate.text(res.n_stampato);
                scarichi_to_invalidate.attr('data-bs-content', 'id scarico: ' + array_stamp);
                scarichi_to_invalidate_suffix.text(suffix);
            } else {
                stampato.hide();
                scarichi_to_invalidate.text('');
                // scarichi_to_invalidate.removeAttr('data-bs-content');
                scarichi_to_invalidate_suffix.text('');
            }

        } else {
            alert.hide();
            $('#isAssociato').val(0);
        }
    })
    .fail(function(err) {
        alert(err);
    })
}

$(function resetGiacenzaModals() {
    const tables = ['#editGiacenza', '#addGiacenza'];

    tables.forEach(el => {
        $(el).on('hidden.bs.modal', function() {
            var input_elements = $(el + ' :input');
            var unita_misura = $(el + ' input[name="unita_misura"]');
            var quantita = $(el + ' .quantita');

            //reset quantita values and unckecks radio inputs
            unita_misura.prop('checked', false);
            quantita.val('');
            quantita.prop('disabled', true);

            //Hide message errors when modal's closed
            input_elements.removeClass('is-invalid');
            $('.invalid-feedback').hide();
        });
    })
});
