require('./ajax');
require('./registro');
require('./magazzino');
require('./formulario');
import { manageRimanenze } from './ajax';

const { data } = require("jquery");

$(function cerDescription() {
    $('#cer_id').on('change', function () {

        var descrizione = $(this).find(':selected').data('descrizione');

        var target = $('#cer_descrizione')
        target.val(descrizione);

    });
});

/* $(function cerDescription() {
    function setCerDesc() {
        var descrizione = $('#cer_id').find(':selected').data('descrizione');

        var target = $('#cer_descrizione')
        target.val(descrizione);
    }

    setCerDesc();

    $('#cer_id').on('load change', function () {
        setCerDesc()
    });
});
 */
$(function gestioneDestinoRifiuto() {

    const radio_recupero = $('#radio_recupero');
    const radio_smaltimento = $('#radio_smaltimento')
    const codice_recupero = $('#codice_destino_recupero');
    const codice_smaltimento = $('#codice_destino_smaltimento');

    function is_recupero_checked(radio_recupero) {
        if (radio_recupero.is(':checked')) {
            codice_recupero.removeAttr('disabled');
            codice_smaltimento.val('');
            codice_smaltimento.attr('disabled', true);
        }
    }

    function is_smaltimento_checked(radio_smaltimento) {
        if (radio_smaltimento.is(':checked')) {
            codice_smaltimento.removeAttr('disabled');
            codice_recupero.val('');
            codice_recupero.attr('disabled', true);
        }
    }

    is_recupero_checked(radio_recupero);
    is_smaltimento_checked(radio_smaltimento);

    radio_recupero.on('click, change', function () {
        is_recupero_checked(radio_recupero);
    });

    radio_smaltimento.on('click, change', function () {
        is_smaltimento_checked(radio_smaltimento);
    });
});

$(function deleteMovimento() {

    $(document).on('click', '.delete-movimento-btn', function () {
        if (confirm('Sei sicuro di voler eliminare questo movimento?')) {
            $.ajax({
                'url': $(this).data('route'),
                'type': 'DELETE',
                'headers': {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                'data': {
                    'movimento_id': $(this).attr('id'),
                },
                'success': function (data) {
                    location.reload();
                },
                'error': function (error) {
                    console.log(error);
                }
            });
        }
    });
});

/**
 * Manages UI for movimento edit and create reagarding input formulario_data
 */
$(function manageDataFormularioBehaviour () {

    const data_formulario = $('#formulario_data');
    const data_formulario_alert = $('.formulario-data-alert');
    const alert_data = $(data_formulario.data('target'));
    const invalid_data_formulario = $('#invalid_data_formulario');

    function displayAlert() {
        function compareDates(inputDate) {
            var today = new Date();
            var dateToCompare = new Date(inputDate.val());
            return dateToCompare > today;
        }
        const display_alert_config = $('[name="config_data_fir_futura"]').val();
        var display_alert = display_alert_config == 'visualizza_avviso' && compareDates(data_formulario);
        return display_alert;
    }

    function checkIfDataFormularioIsinvalid() {
        var data_movimento = new Date($('#data_movimento').val());
        var data_formulario = new Date($('#formulario_data').val());

        return data_movimento < data_formulario;
    }

    function toggleAlert() {
        if(displayAlert() || checkIfDataFormularioIsinvalid()) {
            data_formulario_alert.addClass('text-danger');
        } else {
            data_formulario_alert.removeClass('text-danger');
        }

        if (displayAlert()) {
            alert_data.show();
        } else {
            alert_data.hide();
        }

        if(checkIfDataFormularioIsinvalid()) {
            invalid_data_formulario.show();
        } else {
            invalid_data_formulario.hide();
        }
    }

    toggleAlert();

    $('.related-date').on('change', function () {
        toggleAlert();
    })

    $('form.form-movimento').on('submit', function (ev) {
        //Controllo se la data del formulario è superiore a quella del movimento, nel caso fermo il submit
        if((checkIfDataFormularioIsinvalid())) {
            ev.preventDefault();
            return alert('Non è possibile inserire un movimento con data formulario superiore a quella del movimento.');
        }

        //Se la data del formulario è superiore a oggi e ho attiva l'opzione nelle mie configurazioni mostro un alert ed un messaggio di warning
        if (displayAlert()) {
            alert('Hai inserito una data formulario successiva rispetto a quella odierna!');
            // return confirm('Controllare che i dati siano corretti. Procedere?');
        }
        return confirm('Controllare che i dati siano corretti. Procedere?');
    })
})

/**
 * Manages form fields depending on the movimento tipologia, only for
 */
/*$(function toggleScaricoInputs() {

    const tipologia = $('input[name="tipologia"]');
    const scarico_inputs = $('.scarico-input');

    function make_inputs_ignorable() {
        scarico_inputs.each(function () {
            if(tipologia.filter(':checked').val() == 'carico') {
                $(this).prop('required', false);
                $(this).addClass('d-none');
                $('#rimanenze-alert').hide();
                $('.quantita-alert').removeClass('text-danger');
                $('.nFormulario').show();
                $('.scarico-date').show();
                $('.datiMezzo').show();
            } else if (tipologia.filter(':checked').val() == 'carico-interno' ) {
                $(this).prop('required', false);
                $(this).addClass('d-none');
                $('#rimanenze-alert').hide();
                $('.quantita-alert').removeClass('text-danger');
                $('.nFormulario').hide();
                $('.scarico-date').show();
                $('.datiMezzo').hide();
                $('.intermediario').hide();
            } else if (tipologia.filter(':checked').val() == 'scarico-interno') {
                $(this).prop('required', false);
                $('#rimanenze-alert').hide();
                $('.quantita-alert').removeClass('text-danger');
                $('.nFormulario').hide();
                $('.scarico-date').hide();
                $('.datiMezzo').hide();
                $('.intermediario').hide();
                $(this).removeClass('d-none');
                toggleRimanenzeMagazzinoAlert();
            } else {
                $('.scarico-date').hide();
                $('.datiMezzo').show();
                $('.nFormulario').show();
                $(this).removeClass('d-none');
                toggleRimanenzeMagazzinoAlert();
            }
        })
    }
    make_inputs_ignorable();
    tipologia.on('change', make_inputs_ignorable) ;
}) */

/**
 * Check if peso is inserito
 */
 $(function checkIfPesoIsInserted() {
    const quantita = $('#quantita');
    quantita.on('change keyup', function () {
        if($('#tipologia').is('formulario' || 'formulario-interno')) {
            if (quantita.val() == 0) {
                return alert('Inserire una quantità per selezionare il carico da associare')
            }
        }
        toggleRimanenzeMagazzinoAlert();
    })
})

export function toggleRimanenzeMagazzinoAlert() {
    var rimanenze_alert = $('#rimanenze-alert');
    var quantita_inserita = $('#quantita').val();
    var quantita_alert = $('.quantita-alert');
    var rimanenze  = manageRimanenze();
    var unitaMisura = $('#unita_misura').val();
    const carichi_associabili = $('#movimenti_carico_associabili');
    if (unitaMisura == 'kg') {
        if(quantita_inserita > rimanenze[0].kg) {
            rimanenze_alert.show();
            quantita_alert.addClass('text-danger');

            return carichi_associabili.prop('disabled', true);
        }

        if (quantita_inserita > 0){
            carichi_associabili.prop('disabled', false);
        }
    } else if (unitaMisura == 'lt') {
        if(quantita_inserita > rimanenze[0].lt) {
            rimanenze_alert.show();
            quantita_alert.addClass('text-danger');

            return carichi_associabili.prop('disabled', true);
        }

        if (quantita_inserita > 0){
            carichi_associabili.prop('disabled', false);
        }
    } else if (unitaMisura == 'm3') {
        if(quantita_inserita > rimanenze[0].m3) {
            rimanenze_alert.show();
            quantita_alert.addClass('text-danger');

            return carichi_associabili.prop('disabled', true);
        }

        if (quantita_inserita > 0){
            carichi_associabili.prop('disabled', false);
        }
    }

    rimanenze_alert.hide();
    quantita_alert.removeClass('text-danger');
}

function togglePesoSelezionatoAlertColor(peso_inserito, peso_associato, peso_selezionato) {
    if(parseFloat(peso_inserito.val()) > parseFloat(peso_associato.val())) {
        peso_selezionato.addClass('alert-danger');
        peso_selezionato.removeClass('alert-success');
    } else {
        peso_selezionato.addClass('alert-success');
        peso_selezionato.removeClass('alert-danger');
    }
}

/**
 * Manage form submit
 */
$(function () {
    var peso_associato = $('#peso_associato');
    var peso_inserito = $('#kg');
    var peso_selezionato = $('#peso_selezionato');

    $('#kg').on('change keyup', function() {
        togglePesoSelezionatoAlertColor(peso_inserito, peso_associato, peso_selezionato);
    });

    $('#movimenti_carico_associabili').on('change keyup', function() {
        togglePesoSelezionatoAlertColor(peso_inserito, peso_associato, peso_selezionato)
    });

    $('#movimenti_carico_associati').on('change keyup', function() {
        togglePesoSelezionatoAlertColor(peso_inserito, peso_associato, peso_selezionato)
    });

     // Se il peso inserito come scarico è eccessivo prevengo il submit
    $('.form-carico-scarico').on('submit', function(ev) {
        if($('#scarico').is(':checked')) {
            if (parseFloat(peso_inserito.val()) > parseFloat(peso_associato.val()) && parseFloat(peso_associato.val()) != 0) {
                ev.preventDefault();
                alert('ATTENZIONE! E\' stato inserito un peso maggiore a quello disponibile. Controllare');
                //rendo nuovamente selezionabili le opzioni
                $('#movimenti_carico_associati option').each(function() {
                    $(this).prop('selected', false);
                })
            }

            if (parseFloat(peso_inserito.val()) != 0 && parseFloat(peso_associato.val()) == 0) {
                alert('ATTENZIONE! Stai procedendo senza associare nessun ordine di carico a questo ordine di scarico');
                return true;
            }
        }
    })
})

/**
 * Checks if datas relevant to the associazione carichi has been modified, in which case on submit notifies the user
 */
$(function alertAssociazioneCarichiDataModified() {

    function compareOldDataToNewOnes() {
        var target = $('#relevant_data_associazione');
        var old_values = (target.val());
        let isEditingGiacenza = $('#editGiacenza');

        if(target) {
            var new_values = JSON.stringify({
                cer_id: (!isEditingGiacenza ? ($('#cer_id').val()) : ($('#cer_id_edit_modal').val())),
                classi_pericolo: (!isEditingGiacenza ? ($('#classi_pericolo').val()) : ($('#classi_pericolo_edit_modal').val())),
                data_movimento: (!isEditingGiacenza ? ($('#data_movimento').val()) : ($('#data_movimento_edit_modal').val())),
                kg: (!isEditingGiacenza ? $(('#kg').val()) : ($('#kg_edit_modal').val())),
                litri: (!isEditingGiacenza ? ($('#litri').val()) : ($('#litri_edit_modal').val())),
                metri_cubi: (!isEditingGiacenza ? ($('#metri_cubi').val()) : ($('#metri_cubi_edit_modal').val())),
            });

            if (isEditingGiacenza) {
                var alert = $('#associato_alert');
            } else {
                var alert = $('#relevant-data-alert');
            }

            if(old_values != new_values) {
                alert.addClass('alert-danger');
                alert.removeClass('alert-warning');
            } else {
                alert.addClass('alert-warning');
                alert.removeClass('alert-danger');
            }
        }
    }

    $('#editGiacenza').on('shown.bs.modal', function() {
        compareOldDataToNewOnes();
    });

    $('.relevant-data').on('change keyup', function() {
        compareOldDataToNewOnes();
    });
});

/**
 * Manages quantita inputs interactability
 */
$(function quantitaInteractability() {
    var unita_misura = $('input[name="unita_misura"]');

    function checkQuantita() {
        unita_misura.each(function() {
            var target = $(this).data('target');

            if ($(this).is(":checked")) {
                if(!$('#editGiacenza').hasClass('readonly')) {
                    $(target).prop('disabled', false);
                }
            } else {
                $(target).prop('disabled', true);
                $(target).val('');
            }
        });
    }

    checkQuantita();

    $('#editGiacenza').on('shown.bs.modal', function() {
        checkQuantita();
    });

    unita_misura.on('change', function() {
        checkQuantita();
    });
});

/**
 * Funzione temporanea pre-refactoring calcolo dei pesi associati
 */
$(function markCarichiAssociatiAsSelected() {
    $('.salva-movimento').on('click', function () {
        //rendo selected le opzioni da passare al request e
        $('#movimenti_carico_associati option').each(function() {
            $(this).prop('selected', true);
        })

        //TODO: rivedere funzione che mette required alle cose, magari con un :not()
        $('#movimenti_carico_associati').prop('required', false);
        $('#movimenti_carico_associabili').prop('required', false);
    })
})

/**
 * Cabio il nome dell'input quantita in base all'unità di misura
*/
$('#quantita').on('click', function(){
    var unita_misura = $('#unita_misura').val()
    if(unita_misura == 'm3'){
        $('#quantita').attr('name','metri_cubi');
    } else if (unita_misura == 'lt') {
        $('#quantita').attr('name','litri');
    } else {
        $('#quantita').attr('name','kg');
    }
});

/**
 * Se Cambio l'unita di misura pulisco la quantita
*/
$('#unita_misura').on('change', function(){
    $('#quantita').val('');
});
