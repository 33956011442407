/**
 * Manages the content of the sottocategoria select options 
 */
$('#categoria').on('change', () => {
    var categoriaScelta = $('#categoria').val();

    if (categoriaScelta) {
        switch (categoriaScelta) {

            case 'Bug':
                $('#sottocategoria_bug').removeClass('d-none');
                $('#sottocategoria_bug').prop('disabled', false);

                $('#sottocategoria_empty').addClass('d-none');
                $('#sottocategoria_connettivita').addClass('d-none');
                $('#sottocategoria_assistenza_tecnica').addClass('d-none');
                break;

            case 'Connettività':
                $('#sottocategoria_connettivita').removeClass('d-none');
                $('#sottocategoria_connettivita').prop('disabled', false);

                $('#sottocategoria_empty').addClass('d-none');
                $('#sottocategoria_bug').addClass('d-none');
                $('#sottocategoria_assistenza_tecnica').addClass('d-none');
                break;

            case 'Assistenza_tecnica':
                $('#sottocategoria_assistenza_tecnica').removeClass('d-none');
                $('#sottocategoria_assistenza_tecnica').prop('disabled', false);

                $('#sottocategoria_empty').addClass('d-none');
                $('#sottocategoria_bug').addClass('d-none');
                $('#sottocategoria_connettivita').addClass('d-none');
                break;
        }

    } else {
        $('#sottocategoria_empty').removeClass('d-none');

        $('#sottocategoria_bug').addClass('d-none');
        $('#sottocategoria_connettivita').addClass('d-none');
        $('#sottocategoria_assistenza_tecnica').addClass('d-none');
    }
})

/**
 * Manage maximum number of available characters for a segnalazione to have in it's "Messaggio" field
 */
var messaggioMax = 1000;
$(".messaggio_caratteri_max").text(messaggioMax);
$(".messaggio_caratteri").text('0 /');

//  $(document).ready( () => {
//   $(".messaggio_caratteri").text($('#messaggio').val().length + ' /');
//   if ($('#messaggio').val().length > messaggioMax) {
//     $(".messaggio_caratteri").addClass('text-danger');
//     $(".messaggio_caratteri_max").addClass('text-danger');
//     $(".messaggio_caratteri_span").addClass('text-danger');
//     $('.messaggio_alert').removeClass('d-none');
//   } else if ($('#messaggio').val().length <= messaggioMax) {
//     $(".messaggio_caratteri").removeClass('text-danger');
//     $(".messaggio_caratteri_max").removeClass('text-danger')
//     $(".messaggio_caratteri_span").removeClass('text-danger');
//     $('.messaggio_alert').addClass('d-none');
//   }
// }) 

$("#messaggio").keyup(function () {
    $(".messaggio_caratteri").text($(this).val().length + ' /');

    if ($(this).val().length >= messaggioMax) {
        $(".messaggio_caratteri").addClass('text-danger');
        $(".messaggio_caratteri_max").addClass('text-danger');
        //  $(".messaggio_caratteri_span").addClass('text-danger');
        $('.messaggio_alert').removeClass('d-none');
    } else if ($(this).val().length <= messaggioMax) {
        $(".messaggio_caratteri").removeClass('text-danger');
        $(".messaggio_caratteri_max").removeClass('text-danger')
        // $(".messaggio_caratteri_span").removeClass('text-danger');
        $('.messaggio_alert').addClass('d-none');
    }
});


/**
 * highlights relevant datas in the segnalazioni.list view
 */
$(function () {
    if ($('#da_gestire span').text() == 0) {
        $('#da_gestire span').removeClass('text-danger');
        $('#da_gestire span').addClass('text-success');
    } else if ($('#da_gestire span').text() > 0 && $('#gestite span').text() == 0) {
        $('#gestite span').removeClass('text-success');
        $('#gestite span').addClass('text-danger');
    }
})

