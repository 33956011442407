$(function () {
    $("#cerCountChart").each(function() {
        var options = {
            series: [{
                data: [],
            }],
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                },
                updateOptions: {
                    redrawPaths: true
                },
                fontFamily: "Manrope, sans-serif",
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 1
                },
            },
            dataLabels: {
                enabled: true
            },
            xaxis: {
                categories: [],
            },
            legend: {
                position: 'top',
                offsetY: 30
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                enabled: false
            },
            colors: ['#00a19a', '#91c23d', '#00663d', '#ffb247', '#ff2c32']
        };

        var tipologia = $('#tipologia_registro').val();
        var chart = new ApexCharts(document.querySelector("#cerCountChart"), options);
        chart.render();

        triggerCerChart(tipologia, anno=null)

        function triggerCerChart(tipologia, anno) {
            if ($('#cerCountChart').length) {
                if (!anno) {
                    anno = new Date().getFullYear();
                }
                $.ajax({
                    url: $('#cerCountChart').data('url'),
                }).done(function (data) {
                    if (typeof data[tipologia]?.[anno] === 'undefined') {
                        $('#cerCountChart').hide();
                        $('#cerCountChart').empty();
                        $('#cerCountChart-error-msg').show();
                    } else {
                        $('#cerCountChart').show();
                        $('#cerCountChart').empty();
                        $('#cerCountChart-error-msg').hide();

                        chart.updateSeries(
                            data[tipologia][anno].series
                        )

                        chart.updateOptions({
                            series: [{
                                data: data[tipologia][anno].series,
                            }],

                            xaxis: {
                                categories: data[tipologia][anno].categories,
                            }
                        });

                    }
                });
            }
        }

        $('#select-anno-cerCount-chart').on('change', function () {
            var anno = $(this).val();
            var tipologia = $('#select-tipologia–cerCount-chart').val();
            triggerCerChart(tipologia, anno);
        });

        $('#select-tipologia–cerCount-chart').on('change', function () {
            var anno = $('#select-anno-cerCount-chart').val();
            var tipologia = $(this).val();
            triggerCerChart(tipologia, anno);
        })
    })
});
