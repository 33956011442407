const { values } = require("lodash");

$(function() {
    const nazione = '#nazione';
    const toggle_disabled = $($(nazione).data('toggle-disabled'));
    const empty_string_value = $($(nazione).data('empty-string-value'));
    const null_value = $($(nazione).data('null-value'));

    empty_string_value.val('');

    $(nazione).on('change', function () {
        if ($(`${nazione} option:selected`).val() != 'IT') {
            toggle_disabled.prop('disabled', true)
            null_value.val(null);
            empty_string_value.val('');
        } else {
            toggle_disabled.prop('disabled', false);
        }
    })
})  

/**
 * Manages aggiunta sede checkbox on create anagrafica form
 */
$(function () {
    const personaGiuridica = $('#persona_fisica');
    const partitaIva = $('.partita_iva');
 
    function manageSedeFields() {
        if (personaGiuridica.is(":checked")) {
            partitaIva.hide();
            $(partitaIva).prop('required', false);
        } else {
            partitaIva.show();
            $(partitaIva).prop('required', true);
        }
    }
 
    manageSedeFields();
  
    personaGiuridica.on('change', function () {
        manageSedeFields();
    })
}) 

/**
 * Manages aggiunta sede checkbox on create anagrafica form
 */
$(function () {
   const aggiuntaSede = $('#aggiuntaSede');
   const sedePseudoForm = $('.sedePseudoForm');
   const sedePseudoFormSedeInput = $('.sedePseudoForm .sede_input');

    function manageSedeFields() {
        if (aggiuntaSede.is(":checked")) {
            sedePseudoForm.show();
            sedePseudoFormSedeInput.each(function () {
                $(this).prop('disabled', false);
                $(this).prop('required', true);
            })
        } else {
            sedePseudoForm.hide();
            sedePseudoFormSedeInput.each(function () {
                $(this).prop('disabled', true);
                $(this).prop('required', false);
            })
        }
    }

    manageSedeFields();
 
    aggiuntaSede.on('change', function () {
        manageSedeFields();
    })
})
  