// delete Sede button js

$('.sede-delete').on('click', function () {
    $(this).hide();
    alert("Si è sicuri di volere eliminare la sede selezionata? Chiudere questo alert e premere il tasto rosso.")
    $(this).next().show();
});

$('.user-delete').on('click', function () {
    $(this).hide();
    alert("Si è sicuri di volere eliminare l'utente selezionato? Chiudere questo alert e premere il tasto rosso.")
    // $(this).next().children().show();
    $(this).next().show();
});



$( "#sidebarBtnOff" ).on('click', function() {
    $( "#sidebar" ).fadeOut( "slow", function() {
      $('#contentArea').removeClass('col-md-10').addClass('col-md-12');
    });
    $(this).hide();
    $( "#sidebarBtnOn" ).show();
  });

  $( "#sidebarBtnOn" ).on('click', function() {
    $( "#sidebar" ).fadeIn( "slow", function() {
      $('#contentArea').removeClass('col-md-12').addClass('col-md-10');
    });
    $(this).hide();
    $( "#sidebarBtnOff" ).show();
  });

  /**
   * Empties form fields
   */
  $(function emptyFormFields() {
    $('#empty_form_fields').on('click', function() {
      $('input.filter_field').each(function() {
        $(this).val('');
      });

      $('select.filter_field option').each(function() {
        $(this).attr('selected', false);
      });

      var sede = $('#hidden_sede_detenzione_id').val();

      //Empty all url parameters and reload page
      window.history.replaceState(null, '', window.location.pathname + '?sede_detenzione_id=' + sede + '&include_giacenze=1');      
      location.reload()
    });
  });