require('select2');
$(function() {
    $('select[multiple]').each(function () {
        $(this).select2({
            dropdownParent: $(this).closest('.modal')?.[0] ?? document.body,
            width: '100%',
            placeholder: "Seleziona...",
        })
    });
});
