// taken from https://cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json
// if passed as url, the values cannot be overwritten
// if loaded asynchronously, other calls to .DataTable() on an element may be executed before the options are ready,
// causing an error because we'd be configuring the data table twice.
const lang = {
    sEmptyTable: "Nessun dato presente nella tabella",
    sInfo: "Vista da _START_ a _END_ di _TOTAL_ elementi",
    sInfoEmpty: "Vista da 0 a 0 di 0 elementi",
    sInfoFiltered: "(filtrati da _MAX_ elementi totali)",
    sInfoPostFix: "",
    sInfoThousands: ".",
    sLengthMenu: "Visualizza _MENU_ elementi",
    sLoadingRecords: "Caricamento...",
    sProcessing: "Elaborazione...",
    sSearch: "Cerca:",
    sZeroRecords: "La ricerca non ha portato alcun risultato.",
    oPaginate: {
        sFirst: "Inizio",
        sPrevious: "Precedente",
        sNext: "Successivo",
        sLast: "Fine",
    },
    oAria: {
        sSortAscending: ": attiva per ordinare la colonna in ordine crescente",
        sSortDescending:
            ": attiva per ordinare la colonna in ordine decrescente",
    },
};

const loadTable = (selector, options) => {
    options.language = {
        ...lang,
        processing:
            '<div class="h-100 w-100 d-flex flex-column justify-content-center align-items-center"><i class="fa fa-spinner fa-2x fa-spin mb-2" aria-hidden="true"></i>Caricamento...</div>',
        search: "",
        searchPlaceholder: "Cerca...",
    };
    $(selector).DataTable(options);
};

$(function anagraficheDataTable() {
    loadTable("#anagrafiche-datatable", {
        dom: "Bfrtlip",
        lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "Tutte"],
        ],
        processing: true,
        serverSide: true,
        ajax: $("#anagrafiche-datatable").data("url"),
        columns: [
            { data: "id", name: "id", orderable: true },
            {
                data: "ragione_sociale",
                name: "Ragione_sociale",
                searchable: true,
            },
            { data: "partita_iva", name: "Partita_iva", searchable: true },
            {
                data: "codice_fiscale",
                name: "Codice_fiscale",
                searchable: true,
            },
            { data: "email", name: "Email" },
            { data: "telefono", name: "Telefono" },
            { data: "tipologie_registro", name: "Tipologie_registro" },
            { data: "ruoli_anagrafica", name: "Ruoli_anagrafica" },
            {
                data: "Numero sedi per provincia",
                name: "Numero sedi per provincia",
            },
            { data: "tipologia_anagrafica", name: "Tipologia_anagrafica" },
            { data: "stato", name: "Stato" },
            { data: "totMovimenti", name: "totMovimenti", searchable: true },
            {
                data: "azioni",
                name: "azioni",
                searchable: false,
                orderable: false,
            },
        ],
        buttons: [
            {
                extend: "copy",
                text: "Copia",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
            {
                extend: "excel",
                text: "Excel",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
            {
                extend: "pdf",
                text: "PDF",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
        ],
        responsive: true,
    });
});

$(function anagraficheSubordinateDataTable() {
    loadTable("#anagrafiche-subordinate-datatable", {
        processing: true,
        serverSide: true,
        ajax: $("#anagrafiche-subordinate-datatable").data("url"),
        columns: [
            { data: "id", name: "id" },
            { data: "ragione_sociale", name: "ragione_sociale" },
            { data: "partita_iva", name: "partita_iva" },
            { data: "codice_fiscale", name: "codice_fiscale" },
            { data: "email", name: "email" },
            { data: "telefono", name: "telefono" },
            { data: "ruoli_anagrafica", name: "ruoli_anagrafica" },
            {
                data: "Numero sedi per provincia",
                name: "Numero sedi per provincia",
            },
            // { data: 'totMovimenti', name: 'totMovimenti' },
            {
                data: "azioni",
                name: "azioni",
                searchable: false,
                orderable: false,
            },
        ],
        buttons: [
            {
                extend: "copy",
                text: "Copia",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
            {
                extend: "excel",
                text: "Excel",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
            {
                extend: "pdf",
                text: "PDF",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
        ],
        responsive: true,
    });
});

$(function movimentiCaricoScaricoDataTable() {
    //Tabella DETENTORE
    loadTable("#movimenti-carico-scarico-datatable", {
        processing: true,
        serverSide: true,
        ajax: $("#movimenti-carico-scarico-datatable").data("url"),
        columns: [
            { data: "id", name: "Id" },
            { data: "numero", name: "Numero" },
            { data: "data_movimento", name: "Data_movimento" },
            { data: "tipologia", name: "Tipologia" },
            { data: "cer_id", name: "Cer_id" },
            { data: "classi_pericolo", name: "Classi_pericolo" },
            { data: "stampato_alle", name: "Stampato_alle" },
            { data: "formulario_nr", name: "Formulario_nr" },
            { data: "formulario_data", name: "Formulario_data" },
            { data: "destinato_a", name: "Destinato_a" },
            { data: "codice_destino", name: "Codice_destino" },
            { data: "stato_registro", name: "Stato_registro" },
            { data: "kg", name: "Kg" },
            {
                data: "movimenti_associati",
                name: "Movimenti_associati",
                searchable: false,
                orderable: false,
            },
            {
                data: "anagrafica_intermediario_id",
                name: "Anagrafica_intermediario_id",
            },
            {
                data: "azioni",
                name: "azioni",
                searchable: false,
                orderable: false,
            },
        ],
        responsive: true,
        scrollX: true,
        scrollY: true,
    });
});

$(function movimentiFormularioDataTable() {
    //Tabella DETENTORE MOVIMENTI APERTI
    loadTable("#movimenti-formulario-datatable", {
        processing: true,
        serverSide: true,
        ajax: $("#movimenti-formulario-datatable").data("url"),
        columns: [
            { data: "id", name: "id" },
            { data: "data_movimento", name: "Data_movimento" },
            { data: "tipologia", name: "Tipologia" },
            { data: "cer_id", name: "cer_id" },
            { data: "classi_pericolo", name: "classi_pericolo" },
            { data: "stampato_alle", name: "stampato_alle" },
            { data: "formulario_nr", name: "formulario_nr" },
            { data: "formulario_data", name: "Formulario_data" },
            { data: "destinato_a", name: "Destinato_a" },
            { data: "codice_destino", name: "Codice_destino" },
            { data: "stato_registro", name: "stato_registro" },
            { data: "kg", name: "Kg" },
            { data: "movimenti_associati", name: "movimenti_associati" },
            {
                data: "anagrafica_intermediario_id",
                name: "anagrafica_intermediario_id",
            },
            {
                data: "azioni",
                name: "azioni",
                searchable: false,
                orderable: false,
            },
        ],
        responsive: true,
        scrollX: true,
        scrollY: true,
    });
});

$(function movimentiIntermediazioneDataTable() {
    //Tabella INTERMEDIARIO
    loadTable("#movimenti-intermediazione-datatable", {
        processing: true,
        serverSide: true,
        ajax: $("#movimenti-intermediazione-datatable").data("url"),
        columns: [
            { data: "id", name: "id" },
            { data: "numero", name: "Numero" },
            { data: "data_movimento", name: "Data_movimento" },
            { data: "cer_id", name: "cer_id" },
            { data: "stampato_alle", name: "stampato_alle" },
            { data: "formulario_nr", name: "formulario_nr" },
            { data: "formulario_data", name: "formulario_data" },
            { data: "destinato_a", name: "Destinato_a" },
            { data: "codice_destino", name: "Codice_destino" },
            { data: "kg", name: "Kg" },
            { data: "stato_registro", name: "stato_registro" },
            {
                data: "anagrafica_produttore_id",
                name: "anagrafica_produttore_id",
            },
            {
                data: "anagrafica_trasportatore_id",
                name: "anagrafica_trasportatore_id",
            },
            {
                data: "anagrafica_destinatario_id",
                name: "anagrafica_destinatario_id",
            },
            { data: "azioni", name: "azioni", orderable: false },
        ],
        /*buttons: [
            {
                extend: 'copy',
                text: 'Copia',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'excel',
                text: 'Excel',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'pdf',
                text: 'PDF',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
        ],*/
        responsive: true,
    });
});

$(function cerDatatable() {
    loadTable("#configurazioni-cer-datatable", {
        pagingType: "simple",
        processing: true,
        serverSide: true,
        scrollX: true,
        scrollY: true,
        ajax: $("#configurazioni-cer-datatable").data("url"),
        columns: [
            {
                data: "codice_cer",
                name: "Codice cer",
                orderable: false,
                searchable: true,
            },
            { data: "descrizione", name: "Descrizione" },
            { data: "stato", name: "stato" },
            {
                data: "azioni",
                name: "azioni",
            },
        ],
        responsive: true,
        initComplete: function () {
            this.api()
                .columns()
                .every(function () {
                    var column = this;
                    var input = document.createElement("input");
                    $(input)
                        .appendTo($(column.footer()).empty())
                        .on("change", function () {
                            column
                                .search($(this).val(), false, false, true)
                                .draw();
                        });
                });
        },
    });
});

$(function segnalazioniDataTable() {
    loadTable("#lista_segnalazioni", {
        lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "Tutte"],
        ],
        processing: true,
        serverSide: true,
        ajax: $("#lista_segnalazioni").data("url"),
        columns: [
            { data: "id", name: "id" },
            { data: "created_at", name: "created_at" },
            { data: "user_id", name: "user_id" },
            { data: "categoria", name: "Categoria", searchable: true },
            {
                data: "sottocategoria",
                name: "Sottocategoria",
                searchable: true,
            },
            { data: "allegato", name: "allegato" },
            { data: "messaggio", name: "messaggio" },
            { data: "managed", name: "managed" },
            {
                data: "azioni",
                name: "azioni",
                orderable: true,
                searchable: true,
            },
        ],
        responsive: true,
    });
});

$(function magazzino_entrate() {
    loadTable("#magazzino_entrate", {
        processing: true,
        serverSide: true,
        lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "Tutte"],
        ],
        scrollX: true,
        scrollY: true,
        ajax: $("#magazzino_entrate").data("url"),
        columns: [
            { data: "id", name: "Id", searchable: true },
            { data: "tipologia", name: "Tipologia", searchable: true },
            { data: "numero", name: "Numero", searchable: true },
            {
                data: "data_movimento",
                name: "Data_movimento",
                searchable: true,
            },
            { data: "cer_id", name: "Cer_id", searchable: true },
            {
                data: "classi_pericolo",
                name: "Classi_pericolo",
                searchable: true,
            },
            { data: "kg", name: "Kg", searchable: true, orderable: true },
            {
                data: "movimenti_associati",
                name: "movimenti_associati",
                searchable: false,
                orderable: false,
            },
            {
                data: "azioni",
                name: "azioni",
            },
        ],
        responsive: true,
    });
});

$(function magazzino_uscite() {
    loadTable("#magazzino_uscite", {
        processing: true,
        serverSide: true,
        lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "Tutte"],
        ],
        scrollX: true,
        scrollY: true,
        ajax: $("#magazzino_uscite").data("url"),
        columns: [
            { data: "id", name: "id", searchable: true },
            { data: "numero", name: "Numero", searchable: true },
            {
                data: "data_movimento",
                name: "Data_movimento",
                searchable: true,
            },
            { data: "cer_id", name: "cer_id", searchable: true },
            { data: "classi_pericolo", name: "classi_pericolo" },
            {
                data: "formulario_data",
                name: "Formulario_data",
                searchable: true,
            },
            { data: "kg", name: "Kg", searchable: true, orderable: true },
            {
                data: "movimenti_associati",
                name: "movimenti_associati",
                searchable: false,
                orderable: false,
            },
            {
                data: "anagrafica_intermediario_id",
                name: "anagrafica_intermediario_id",
                searchable: true,
            },
            {
                data: "azioni",
                name: "azioni",
                searchable: true,
            },
        ],
        responsive: true,
    });
});

$(function anagraficheDelegantiTable() {
    loadTable("#deleganti-datatable", {
        dom: "Bfrtlip",
        lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "Tutte"],
        ],
        processing: true,
        serverSide: true,
        ajax: $("#deleganti-datatable").data("url"),
        columns: [
            { data: "id", name: "id", orderable: true, class: "align-middle" },
            {
                data: "utente",
                name: "Utente",
                searchable: true,
                class: "align-middle",
            },
            {
                data: "ragione_sociale",
                name: "Ragione_sociale",
                orderable: true,
                searchable: true,
                class: "align-middle",
            },
            {
                data: "partita_iva",
                name: "Partita_iva",
                searchable: true,
                class: "align-middle",
            },
            {
                data: "codice_fiscale",
                name: "Codice_fiscale",
                searchable: true,
                class: "align-middle",
            },
            { data: "email", name: "Email", class: "align-middle" },
            { data: "telefono", name: "Telefono", class: "align-middle" },
            {
                data: "totMovimenti",
                name: "totMovimenti",
                searchable: true,
                class: "align-middle",
            },
            {
                data: "stato",
                name: "stato",
                class: "align-middle",
                createdCell: function (td, cellData, rowData, row, col) {
                    if (rowData["stato"] == `ACCETTATA`) {
                        $(td).html(
                            $(
                                '<div class="bg-success text-white rounded text-center">ACCETTATA</div>'
                            )
                        );
                    } else if (rowData["stato"] == `DISATTIVATA`) {
                        $(td).html(
                            $(
                                '<div class="bg-danger text-white rounded text-center">DISATTIVATA</div>'
                            )
                        );
                    } else {
                        $(td).html(
                            $(
                                `<div class="bg-warning text-dark rounded text-center">${cellData}</div>`
                            )
                        );
                    }
                },
            },
            { data: "azioni", name: "azioni", class: "align-middle" },
        ],
        /*buttons: [
            {
                extend: 'copy',
                text: 'Copia',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'excel',
                text: 'Excel',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'pdf',
                text: 'PDF',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
        ],*/
        responsive: true,
    });
});

$(function logTable() {
    loadTable("#log-datatable", {
        dom: "Bfrtlip",
        lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "Tutte"],
        ],
        processing: true,
        serverSide: true,
        ajax: $("#log-datatable").data("url"),
        columns: [
            { data: "id", name: "Id", orderable: true },
            { data: "tipo", name: "Tipo", searchable: true },
            { data: "descrizione", name: "Descrizione", searchable: true },
            { data: "pagina", name: "Pagina", searchable: true },
            { data: "user id", name: "User id", searchable: true },
            { data: "fatto il", name: "Fatto il" },
        ],
        buttons: [
            {
                extend: "copy",
                text: "Copia",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
            {
                extend: "excel",
                text: "Excel",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
            {
                extend: "pdf",
                text: "PDF",
                className: "btn btn-default",
                exportOptions: {
                    columns: "th:not(:last-child)",
                },
            },
        ],
        responsive: true,
    });
});
