const { transform } = require("lodash");

$('.toggleLeaves').on('click', () => {
    $('#leaves').slideToggle('slow');
    $('.toggleLeaves .fa-chevron-down').toggle();
    $('.toggleLeaves .fa-chevron-up').toggle();
})

$('.toggleWater').on('click', () => {
    $('#water').slideToggle('slow');
    $('.toggleWater .fa-chevron-down').toggle();
    $('.toggleWater .fa-chevron-up').toggle();
})

$('.toggleEarth').on('click', () => {
    $('#earth').slideToggle('slow');
    $('.toggleEarth .fa-chevron-down').toggle();
    $('.toggleEarth .fa-chevron-up').toggle();
})

$(document).ready(() => {
    var supported = $('#supportedBrowser').val()
    if ( supported == 'notSupported') {
        $('#browserDetection').modal('show');
    }
    console.log($('#confirmSignupModal'));
    $('#confirmSignupModal').modal('show');
})

/**
 * Opens login modal on load if validation fails
*/
$(function () {
    if ($('#validationError').val() == 'modalOpened') {
        $("#loginModal").modal("show");
    }
});

$(function () {
    if ($('#validationErrorSignUp').val() == 'modalOpened') {
        $("#signModal").modal("show");
    }
});

/**
 * Manages aggiunta sede checkbox on create anagrafica form
*/

$(function () {
    const personaGiuridica = $('#personaFisica');
    const partitaIva = $('.partita_iva');

    function manageSedeFields() {
        if (personaGiuridica.is(":checked")) {
            partitaIva.hide();
            $(partitaIva).prop('required', false);
        } else {
            partitaIva.show();
            $(partitaIva).prop('required', true);
        }
    }

    manageSedeFields();

    personaGiuridica.on('change', function () {
        manageSedeFields();
    })
});
  
// //Gestione menù earth per mobile
// $('.img-container').on('click', () => {
//     $('.collapsible').addClass('d-flex');
//     $('.collapsible').addClass('px-2');
//     $('.collapsible').slideDown('slow', () => {
//         $('.collapsible').removeClass('h-0');
//         $('.collapsible').css('height', 175 + 'px');
//     })
// })

// $('#earth_tecnologia').on('mouseenter focus mouseleave', () => {
//     $('#earth_tecnologia').toggleClass('active-img');
//     $('#earth_tecnologia').parents('.img-container').toggleClass('invisible-border');
//     $('.collapsible h4').text('Tecnologia');
//     $('.collapsible h5').text('Software innovativo che utilizza gli ultimi modelli di gestione e trasporto dei rifiuti');
// })

// $('#earth_ecologia_circolare').on('mouseenter focus mouseleave', () => {
//     $('#earth_ecologia_circolare').toggleClass('active-img')
//     $('#earth_ecologia_circolare').parents('.img-container').toggleClass('invisible-border')
//     $('.collapsible h4').text('Economia circolare')
//     $('.collapsible h5').text('Biroser favorisce il ritiro dei rifiuti per un maggior guadagno. Dall\'isola ecologica direttamente alla tua azienda')
// })

// $('#earth_industria_4_0').on('mouseenter focus mouseleave', () => {
//     $('#earth_industria_4_0').toggleClass('active-img')
//     $('#earth_industria_4_0').parents('.img-container').toggleClass('invisible-border')
//     $('.collapsible h4').text('Industria 4.0')
//     $('.collapsible h5').text('Processo tecnologico per cui tutte le fasi di gestione, trasporto e lavorazione sono interconnesse e automatizzate')
// })

// $('#earth_rispetto_norme_vigenti').on('mouseenter focus mouseleave', () => {
//     $('#earth_rispetto_norme_vigenti').toggleClass('active-img')
//     $('#earth_rispetto_norme_vigenti').parents('.img-container').toggleClass('invisible-border')
//     $('.collapsible h4').text('Rispetto delle norme vigenti')
//     $('.collapsible h5').text('Biroser opera nel rispetto della natura e delle norme vigenti sulla gestione dei rifiuti')
// })

// $('#earth_gestione').on('mouseenter focus mouseleave', () => {
//     $('#earth_gestione').toggleClass('active-img')
//     $('#earth_gestione').parents('.img-container').toggleClass('invisible-border')
//     $('.collapsible h4').text('Gestione')
//     $('.collapsible h5').text('Gestione del flusso dei dati semplice e veloce, a portata di un click')
// })

// $('#earth_organizzazione').on('mouseenter focus mouseleave', () => {
//     $('#earth_organizzazione').toggleClass('active-img')
//     $('#earth_organizzazione').parents('.img-container').toggleClass('invisible-border')
//     $('.collapsible h4').text('Organizzazione')
//     $('.collapsible h5').text('Gestione del flusso dei dati semplice e veloce, a portata di un click')
// })