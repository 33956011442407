const { values } = require("lodash");  

/**
 * Manages aggiunta sede checkbox on create anagrafica form
 */
$(function () {
   const aggiuntaUtente = $('#aggiuntaUtente');
   const utentePseudoForm = $('.utentePseudoForm');
   const utentePseudoFormUtenteInput = $('.utentePseudoForm .utente_input');
    
    function manageUtenteFields() {
        if (aggiuntaUtente.is(":checked")) {
            utentePseudoForm.show();
            utentePseudoFormUtenteInput.each(function () {
                $(this).prop('disabled', false);
                $(this).prop('required', true);
            })
        } else {
            utentePseudoForm.hide();
            utentePseudoFormUtenteInput.each(function () {
                $(this).prop('disabled', true);
                $(this).prop('required', false);
            })
        }
    }

    manageUtenteFields();
 
    aggiuntaUtente.on('change', function () {
        manageUtenteFields();
    })
})
$('#submitDelega').hide();
$('#checkCodFiscale').on('click', function () {
    var codFiscaleValue = $('#codice_fiscale').val();
    const target = $('#anagrafica_target');
    checkCodFiscale(codFiscaleValue,target);
});

function checkCodFiscale(codFiscaleValue,target){
    $.ajax({
        'url': $('#codice_fiscale').data('route'),
        'type': 'GET',
        'data': {
            'role': $('#codice_fiscale').data('role'),
            'cod_fiscale': codFiscaleValue
        },
        'success': function (data) {
            var dataDecode = JSON.parse(data);
            if(dataDecode != null){
                target.children('span').remove()
                if(dataDecode.ruoli_anagrafica == '["delegato"]'){
                    target.append('<span>Questo codice fiscale appartiene alla Ragione Sociale <b class="fs-3">' + dataDecode.ragione_sociale + '</b></span>');
                    $('#submitDelega').show();
                } else if ($('#codice_fiscale').data('role') == 'delegato') {
                    target.append('<span>Questo codice fiscale appartiene alla Ragione Sociale <b class="fs-3">' + dataDecode.ragione_sociale + '</b></span>');
                    $('#submitDelega').show();
                } else {
                    target.append('<span>Errore nella ricerca del codice fiscale, riprova.</span>');
                }
            } else {
                target.append('<span>Questo codice fiscale non è presente sul portale Biroser. Verifica che il codice inserito sia corretto.</span>');
            }
        },
        'error': function (error) {
            console.log(error);
        }
    });
}  