const { values } = require("lodash");  

$('#checkPesi').on('click', function () {
    let anagraficaId = $('#anagraficaId').val();
    let anno = $('#anno').val();
    
    checkPesoResiduo(anagraficaId,anno);
});

/**
 * verifica la presenza di carichi non "scaricati" (il cui peso residuo è pari a 0)
 */
function checkPesoResiduo(anagraficaId,anno){
    $.ajax({
        'url': $('#checkPesi').data('route'),
        'type': 'GET',
        'data': {
            'anno': anno,
            'anagrafica_id': anagraficaId
        },
        'success': function (data) {
            var dataDecode = JSON.parse(data);
            target = $('#nr_movimenti');
            console.log(dataDecode);
            if(dataDecode != null){
                target.children('span').remove()
                target.append('Sono presenti <b class="fs-3">' + dataDecode + '</b> movimenti con peso residuo non scaricato.');
            } else {
                target.append('Non sono presnti movimenti con peso pari a 0.');
            }
        },
        'error': function (error) {
            console.log(error);
        }
    });
}