// require ('bootstrap');
require("./bootstrap");
require("./select2");
require("datatables.net-dt");
require("datatables.net-buttons-bs5")();
// require( 'datatables.net-buttons/js/buttons.html5.js' )();
// require( 'datatables.net-searchpanes-bs5' )();
require("./istat");
require("./datatable/datatable");
require("./buttons/buttons");
require("./movimenti/movimenti");
require("./charts/annoMese");
require("./charts/cerCount");
require("./welcome");
require("./segnalazioni");
require("./sedi");
require("./delegation");
// var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
// var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//   return new bootstrap.Tooltip(tooltipTriggerEl)
// })

/**
 * Activates bootstrap popovers globally
 *
 * var tables is an array which includes those datatables that have a popover in its rows
 */
$(function () {
    var tables = [
        $("#movimenti-intermediazione-datatable"),
        $("#movimenti-carico-scarico-datatable"),
        $("#movimenti-formulario-datatable"),
        $("#lista_segnalazioni"),
        $("#magazzino_entrate"),
        $("#magazzino_uscite"),
    ];

    tables.forEach((element) => {
        element.on("draw.dt", function () {
            var popoverTriggerList = [].slice.call(
                document.querySelectorAll('[data-bs-toggle="popover"]')
            );
            var popoverList = popoverTriggerList.map(function (
                popoverTriggerEl
            ) {
                return new bootstrap.Popover(popoverTriggerEl);
            });
        });
        var popoverTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="popover"]')
        );
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl);
        });
    });
});

/**
 * Manage Ui/Ux related to print action for elenco movimenti
 */

$(function () {
    var data_stampa = $("#data_stampa");
    const alert_stampa = $(".alert-stampa");
    const print_button = $("#printButton");
    const print_dropdown = $("#print-dropdown");
    const print_button_overlay = $(".print-button-overlay");
    const info_baloon = $(".info-baloon");

    if (print_button.prop("disabled", true)) {
        print_button_overlay.on("mouseenter", function () {
            info_baloon.show(200);
        });

        print_button_overlay.on("mouseleave", function () {
            info_baloon.hide(200);
        });
    }

    data_stampa.on("change", function () {
        var date = data_stampa.val();
        if (date) {
            alert_stampa.show(250);
            print_dropdown.prop("disabled", false);
            print_button.prop("disabled", false);
            print_button_overlay.hide();
            print_dropdown.parent().removeAttr("data-bs-toggle");
        } else {
            alert_stampa.hide(250);
            print_dropdown.prop("disabled", true);
            print_button.prop("disabled", true);
            print_button_overlay.show();
            print_dropdown.parent().attr("data-bs-toggle", "tooltip");
        }
    });
});

/**
 * Manage maximum number of available characters for a movimento to have in it's "Note" field when paper printed
 */
const noteIntermediazioneMax = 250;
const note_intermediazione = $("#note_intermediazione");
const note_intermediazione_caratteri_max = $(
    ".note_intermediazione_caratteri_max"
);
const note_intermediazione_caratteri = $(".note_intermediazione_caratteri");
const note_intermediazione_caratteri_span = $(
    ".note_intermediazione_caratteri_span"
);
const note_intermediazione_alert = $(".note_intermediazione_alert");
const note_intermediazione_shared = $(".note_intermediazione_shared");

note_intermediazione_caratteri_max.text(noteIntermediazioneMax);
note_intermediazione_caratteri.text("0 /");

$(function () {
    $(".form-intermediazione").each(function () {
        note_intermediazione_caratteri.text(
            note_intermediazione.val().length + " /"
        );
        if (note_intermediazione.val().length > noteIntermediazioneMax) {
            note_intermediazione_shared.addClass("text-danger");
            note_intermediazione_caratteri_span.addClass("text-danger");
            note_intermediazione_alert.removeClass("d-none");
        } else if (
            note_intermediazione.val().length <= noteIntermediazioneMax
        ) {
            note_intermediazione_shared.removeClass("text-danger");
            note_intermediazione_caratteri_span.removeClass("text-danger");
            note_intermediazione_alert.addClass("d-none");
        }
    });
});

note_intermediazione.on("keyup", function () {
    note_intermediazione_caratteri.text($(this).val().length + " /");
    if ($(this).val().length > noteIntermediazioneMax) {
        note_intermediazione_shared.addClass("text-danger");
        note_intermediazione_caratteri_span.addClass("text-danger");
        note_intermediazione_alert.removeClass("d-none");
    } else if ($(this).val().length <= noteIntermediazioneMax) {
        note_intermediazione_shared.removeClass("text-danger");
        note_intermediazione_caratteri_span.removeClass("text-danger");
        note_intermediazione_alert.addClass("d-none");
    }
});

/**
 * Manage maximum number of available characters for a movimento to have in it's "Annotazioni" field when paper printed
 */
const annotazioniMax = 175;
$(".annotazioni_caratteri_max").text(annotazioniMax);
$(".annotazioni_caratteri").text("0 /");

$(function () {
    $(".form-intermediazione").each(function () {
        $(".annotazioni_caratteri").text($("#annotazioni").val().length + " /");
        if ($("#annotazioni").val().length > annotazioniMax) {
            $(".annotazioni_caratteri").addClass("text-danger");
            $(".annotazioni_caratteri_max").addClass("text-danger");
            $(".annotazioni_caratteri_span").addClass("text-danger");
            $(".annotazioni_alert").removeClass("d-none");
        } else if ($("#annotazioni").val().length <= annotazioniMax) {
            $(".annotazioni_caratteri").removeClass("text-danger");
            $(".annotazioni_caratteri_max").removeClass("text-danger");
            $(".annotazioni_caratteri_span").removeClass("text-danger");
            $(".annotazioni_alert").addClass("d-none");
        }
    });
});

$("#annotazioni").keyup(function () {
    $(".annotazioni_caratteri").text($(this).val().length + " /");

    if ($(this).val().length > annotazioniMax) {
        $(".annotazioni_caratteri").addClass("text-danger");
        $(".annotazioni_caratteri_max").addClass("text-danger");
        $(".annotazioni_caratteri_span").addClass("text-danger");
        $(".annotazioni_alert").removeClass("d-none");
    } else if ($(this).val().length <= annotazioniMax) {
        $(".annotazioni_caratteri").removeClass("text-danger");
        $(".annotazioni_caratteri_max").removeClass("text-danger");
        $(".annotazioni_caratteri_span").removeClass("text-danger");
        $(".annotazioni_alert").addClass("d-none");
    }
});

$(function () {
    // activating bootstrap tooltips - this method also works on dynamically added tooltips
    $("body").tooltip({
        selector: '[data-bs-toggle="tooltip"]',
    });
});

// changing icon in collapsible filters
$("#collapsibleFilters").on("hide.bs.collapse", function () {
    $("#filtersIcon").removeClass("fa-chevron-up").addClass("fa-chevron-down");
});
$("#collapsibleFilters").on("show.bs.collapse", function () {
    $("#filtersIcon").removeClass("fa-chevron-down").addClass("fa-chevron-up");
});

$(function () {
    const rentriInfoModalLastShown = localStorage.getItem(
        "rentriInfoModalLastShown"
    );
    const rentriInfoModalDoNotShowAgain = localStorage.getItem(
        "rentriInfoModalDoNotShowAgain"
    );
    if (
        !rentriInfoModalLastShown ||
        (!rentriInfoModalDoNotShowAgain &&
            Date.now() - Number(rentriInfoModalLastShown) > 24 * 60 * 60 * 1000)
    ) {
        $("#rentriInfoModal").modal("show");
    }
    $("#rentriInfoModal").on("submit", function (e) {
        e.preventDefault();
        const userChoice = e.originalEvent?.submitter?.value;
        if (userChoice === "never") {
            localStorage.setItem("rentriInfoModalLastShown", Date.now());
            localStorage.setItem("rentriInfoModalDoNotShowAgain", true);
            $("#rentriInfoModal").modal("hide");
        } else if (userChoice === "later") {
            localStorage.setItem("rentriInfoModalLastShown", Date.now());
            $("#rentriInfoModal").modal("hide");
        }
    });
});
