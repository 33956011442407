require('./movimenti');
import { toggleRimanenzeMagazzinoAlert } from './movimenti';


const { data } = require("jquery");

var peso = 0;

/**
 * Calculate rimanenze on the create or edit carico-scarico view
 */
export function manageRimanenze() {
    var rimanenzeKg = 0;
    var rimanenzeLt = 0;
    var rimanenzeM3 = 0;
    var rimKg = $('#rimanenze_kg');
    var rimLt = $('#rimanenze_lt');
    var rimM3= $('#rimanenze_m3');
    const carichi_magazzino = $("#movimenti_carico_associabili option");
    const carichi_associati = $("#movimenti_carico_associati option");
    
    var sum_mag = carichi_magazzino.map(function () {
        if ($(this).data('peso') && $(this).data('unita_misura') == 'kg') {
            rimanenzeKg += parseFloat($(this).data('peso'));
        } else if ($(this).data('peso') && $(this).data('unita_misura') == 'lt') {
            rimanenzeLt += parseFloat($(this).data('peso'));
        } else if ($(this).data('peso') && $(this).data('unita_misura') == 'm3') {
            rimanenzeM3 += parseFloat($(this).data('peso'));
        }
    }).get();

    /*var sum_ass = carichi_associati.map(function () {
        if ($(this).data('peso')) {
            rimanenze += parseFloat($(this).data('peso'));
        }
    }).get();*/
    
    rimKg.text(rimanenzeKg.toFixed(2) + 'kg');
    rimLt.text(rimanenzeLt.toFixed(2) + 'lt');
    rimM3.text(rimanenzeM3.toFixed(2) + 'm3');
    let rimanenzeArray = [{'kg':rimanenzeKg,'lt':rimanenzeLt,'m3':rimanenzeM3}];
    return rimanenzeArray;
}


/**
 * Manages peso UI behaviour on page load
 */
$(function pesoOnLoad() {
    $('.form-carico-scarico').each(function () {
        var peso_associato = $('#peso_associato').val();
        if (peso_associato == 0) {
            $('#peso_selezionato_text').text(0),
                $('#peso_associato').val(0);
        } else {
            $('#peso_selezionato_text').text(peso_associato);
            peso = parseFloat(peso_associato);
        }
    })
})

//TODO: SPOSTARE IN ./MOVIMENTI
/**
 * Manages pesi associati on selection
 */
$(function () {
    const carichi_associabili = $('#movimenti_carico_associabili');

    carichi_associabili.on('change', this, function () {
        var result = $("#movimenti_carico_associabili option:selected").map(function () {
            peso += parseFloat($(this).data("peso"));

            //$('#movimenti_carico_associati').append($(this).prop('selected', false));
            return $(this).data("peso");
        }).get();
        manageRimanenze();

        $('#peso_associato').val(peso.toFixed(2));
        $('#peso_selezionato_text').text(peso.toFixed(2));
    })
})

//TODO: SPOSTARE IN ./MOVIMENTI
/**
 * Removes pesi associati on selection
 */
$(function () {
    const carichi_associati = $('#movimenti_carico_associati');
    carichi_associati.on('change', this, function () {
        var result = $("#movimenti_carico_associati option:selected").map(function () {
            peso -= parseFloat($(this).data("peso"));
            $('#movimenti_carico_associabili').append($(this).prop('selected', false));
            return $(this).data("peso");
        }).get();
        manageRimanenze();

        $('#peso_associato').val(peso.toFixed(2));
        $('#peso_selezionato_text').text(peso.toFixed(2));
    })
})

/**
 * Populates the sedi selects in a movimento edit or view form
 */
$(function getSediGroup() {
    var route = $('#anagrafica_produttore_id').data('route');


    function ajaxSedi(parent, target, role) {
        let anagraficaId = $(parent).find(":selected").data(role);
        manageSede($(parent), anagraficaId, route, $(target))
    }

    function emptySede(target) {
        return target.children('option').remove();
    }

    function manageSede(origin, anagraficaId, route, target) {
        if (origin.val() == '') {
            return emptySede(target)
        }

        var sede_selected = $('#'+target.attr('id')).data('selected');
        return getSedi(anagraficaId, route, target, sede_selected)
    }

    if($('#anagrafica_produttore_id').length) {
        ajaxSedi('#anagrafica_produttore_id', '#sede_produzione_id', 'produttore');
    }

    if ($('#anagrafica_trasportatore_id').length) {
        ajaxSedi('#anagrafica_trasportatore_id', '#sede_trasportatore_id', 'trasportatore');
    }

    if ($('#anagrafica_destinatario_id').length) {
        ajaxSedi('#anagrafica_destinatario_id', '#sede_destinatario_id', 'destinatario');
    }

    $('#anagrafica_produttore_id').on('change', function () {
        var target = $('#sede_produzione_id');
        var anagraficaId = $(this).find(":selected").data('produttore');
        // getSedi(anagraficaId, route, target)
        manageSede($(this), anagraficaId, route, target)
    });

    $('#anagrafica_trasportatore_id').on('change', function () {
        var target = $('#sede_trasportatore_id');
        var anagraficaId = $(this).find(":selected").data('trasportatore');
        // getSedi(anagraficaId, route, target)
        // emptySede($(this), target);
        manageSede($(this), anagraficaId, route, target)
    });

    $('#anagrafica_destinatario_id').on('change', function () {
        var target = $('#sede_destinatario_id');
        var anagraficaId = $(this).find(":selected").data('destinatario');
        // getSedi(anagraficaId, route, target)
        manageSede($(this), anagraficaId, route, target)
    });

    $('#anagrafica_intermediario_id').on('change', function () {
        var target = $('#sede_intermediario_id');
        var anagraficaId = $(this).find(":selected").data('intermediario');
        var routeSedi = $(this).data('route');
        if ($(this).val() != '') {
            target.prop('required', true)
            getSedi(anagraficaId, routeSedi, target);
        }
    });
    

});


function getSedi(anagraficaId, route, target, sede_selected) {
    $.ajax({
        'url': route,
        'type': 'GET',
        'data': {
            'anagrafica_id': anagraficaId
        },
        'success': function (data) {
            var dataDecode = JSON.parse(data);
            target.find('option').remove();

            if (dataDecode.length > 0) {
                dataDecode.forEach(element => {
                    var tag_selected = element.id == sede_selected ?'selected':'';
                    if (element.nazione != 'IT') {
                        target.append('<option value="' + element.id + '" '+ tag_selected +'>' + element.toponimo + ' ' + element.indirizzo + ', ' + element.numero_civico + ' (' + element.nazione + ') </option>')
                    } else {
                        target.append('<option value="' + element.id + '" '+ tag_selected +'>' + element.toponimo + ' ' + element.indirizzo + ', ' + element.numero_civico + ' - ' + element.comune + '(' + element.provincia + ') </option>')
                    }
                });
            } else {
                target.append('<option class="text-center" value=""> - nessuna sede disponibile - </option>');
            }
        },
        'error': function (error) {
            console.log(error);
        }
    });
}



$(function getAnagGroup() {
    var route = $('#anagrafica_intermediario_id').data('anag');

    function emptyInput(...inputs) {
        inputs.forEach(el => {
            return el.val('');
        })
        $('#sede_intermediario_id option').remove();
    }

    function getDatiAnagrafici() {
        var anagraficaId = $('#anagrafica_intermediario_id').find(":selected").data('intermediario');
        var codiceFiscaleTarget = $('#codice_fiscale_intermediario');
        var iscrAlboTarget = $('#iscrizione_albo_nr_intermediario');

        if ($('#anagrafica_intermediario_id').val() == '') {
            $('#sede_intermediario_id').prop('required', false);
            return emptyInput(codiceFiscaleTarget, iscrAlboTarget);
        }

        return getAnagrafica(anagraficaId, route, codiceFiscaleTarget, iscrAlboTarget);
    }

    if ($('.form-carico-scarico').hasClass('form-edit')) {
        getDatiAnagrafici();
    }

    $('#anagrafica_intermediario_id').on('change', function () {
        getDatiAnagrafici();
    });
});

function getAnagrafica(anagraficaId, route, codiceFiscaleTarget, iscrAlboTarget) {
    $.ajax({
        'url': route,
        'type': 'GET',
        'data': {
            'anagrafica_id': anagraficaId
        },
        'success': function (data) {
            var dataDecode = JSON.parse(data);

            codiceFiscaleTarget.val(dataDecode.codice_fiscale || 'n/d');
            iscrAlboTarget.val(dataDecode.iscrizione_albo_nr || 'n/d');
        },
        'error': function (error) {
            console.log(error);
        }
    });
}

//TODO:rifattorizzare
function getOldCarichiAssociati(data_mov, id, numero) {
    const old_carico = $('.old-carico');
    const movimenti_associabili = $('#movimenti_carico_associabili option.carico_scelto');
    old_carico.each(function () {
        var old_c = $(this);
        var old_c_peso = old_c.data('peso');
        movimenti_associabili.each(function () {
            var new_c = $(this);
            var new_c_peso = new_c.data('peso');
            if (old_c.val() == new_c.val()) {
                new_c_peso += old_c_peso
                new_c.text('#' + (numero || id) + ') ' + data_mov + ': ' + new_c_peso + 'kg')
                new_c.data('peso', new_c_peso);
                new_c.attr('data-peso', new_c_peso);
            }
        });
    });
}

/**
 * Empty carichi associati select
 */
function removeCarichiAssociati() {
    $('#movimenti_carico_associati option').remove()
}


//TODO: rifattorizzare 
$(function getCarichiAssociabiliGroup() {
    var target = $('#movimenti_carico_associabili');
    var route = target.data('route');
    var anagraficaId = target.data('anagrafica');
    var sede_detenzione_id = $('#sede_registro_id').val();
    var movimento_scarico_id = $('#movimento_scarico_id').val();
    var anno = $('#anno').val();
    var cer_id = $('#cer_id').val();
    var classi_pericolo = $('#classi_pericolo').val();
    var data_movimento = $('#data_movimento').val();
    var codice_destino = $('.cod_destino').val();

    //chiamo la funzione on load nel caso si tratte della form di edit
    if ($('.form-carico-scarico').hasClass('form-edit')) {
        getCarichiAssociabili(anagraficaId, movimento_scarico_id, route, target, sede_detenzione_id, anno, data_movimento, cer_id, classi_pericolo, codice_destino);
    }

    $('.form-carico-scarico').each(function () {
        if($('#tipologia').val() == 'formulario' || $('#tipologia').val() == 'formulario-interno'){
            $('#data_movimento').on('change', function () {
                var data_movimento = $(this).val();
                getCarichiAssociabili(anagraficaId, movimento_scarico_id, route, target, sede_detenzione_id, anno, data_movimento, cer_id, classi_pericolo);
                removeCarichiAssociati();
                $('#peso_selezionato_text').text(0),
                    $('#peso_associato').val(0);
                peso = 0;
            })

            $('#cer_id').on('change', function () {
                cer_id = $(this).val();
                var data_movimento = $('#data_movimento').val();
                $('option.carico_scelto').each(function () {
                    $(this).remove();
                });
                getCarichiAssociabili(anagraficaId, movimento_scarico_id, route, target, sede_detenzione_id, anno, data_movimento, cer_id, classi_pericolo, codice_destino);
                removeCarichiAssociati();
                    $('#peso_selezionato_text').text(0),
                    $('#peso_associato').val(0);
                    peso = 0;
            });

            $('#classi_pericolo').on('change', function () {
                classi_pericolo = $(this).val();
                var data_movimento = $('#data_movimento').val();
                $('option.carico_scelto').each(function () {
                    $(this).remove();
                });

                getCarichiAssociabili(anagraficaId, movimento_scarico_id, route, target, sede_detenzione_id, anno, data_movimento , cer_id, classi_pericolo, codice_destino);
                removeCarichiAssociati();
                $('#peso_selezionato_text').text(0),
                    $('#peso_associato').val(0);
                peso = 0;
            });

            $('.cod_destino').on('change', function () {
                codice_destino = $(this).val();
                var data_movimento = $('#data_movimento').val();
                $('option.carico_scelto').each(function () {
                    $(this).remove();
                });
                getCarichiAssociabili(anagraficaId, movimento_scarico_id, route, target, sede_detenzione_id, anno, data_movimento, cer_id, classi_pericolo, codice_destino);
                removeCarichiAssociati();
                    $('#peso_selezionato_text').text(0),
                    $('#peso_associato').val(0);
                    peso = 0;
            });
        }
    })
});

function getCarichiAssociabili(id, movimento_scarico_id, route, target, sede_detenzione_id, anno, data_movimento, cer_id, classi_pericolo, codice_destino) {
    $.ajax({
        'url': route,
        'type': 'GET',
        'data': {
            'anagrafica_id': id,
            'sede_detenzione_id': sede_detenzione_id,
            'anno': anno,
            'data_movimento': data_movimento,
            'cer_id': cer_id,
            'codice_destino': codice_destino,
            'classi_pericolo': classi_pericolo,
            'movimento_scarico_id': movimento_scarico_id,
        },
        'success': function (data) {
            var dataDecode = JSON.parse(data);
            target.find('option').remove();
            
            if (dataDecode.length > 0) {
                console.log(dataDecode);
                dataDecode.forEach(element => {
                    // Se il movimento è stato precedentemente selezionato, applico il selected
                    var selected = element.selected ?  "selected" : "";

                    if (element.tipologia == 'giacenza') {
                        if (element.kg != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.kg + '">Giacenza del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.kg + 'kg' /* + 'peso scaricato:' + (element.peso_residuo) + 'kg' + 'peso lordo:' + (element.kg).toFixed(2) + 'kg' */ + '</option>');
                        } else if (element.litri != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.litri + '">Giacenza del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.litri + 'Lt' /* + 'peso scaricato:' + (element.peso_residuo) + 'Lt' + 'peso lordo:' + (element.kg).toFixed(2) + 'Lt' */ + '</option>');
                        } else if (element.metri_cubi != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.metri_cubi + '">Giacenza del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.metri_cubi + 'm3' /* + 'peso scaricato:' + (element.peso_residuo) + 'm3' + 'peso lordo:' + (element.kg).toFixed(2) + 'm3' */ + '</option>');
                        }
                    } else if(element.tipologia == 'giacenza-iniziale'){
                        if (element.kg != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.kg + '">Giacenza iniziale del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.kg + 'kg' /* + 'peso scaricato:' + (element.peso_residuo) + 'kg' + 'peso lordo:' + (element.kg).toFixed(2) + 'kg' */ + '</option>');
                        } else if (element.litri != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.litri + '">Giacenza iniziale del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.litri + 'Lt' /* + 'peso scaricato:' + (element.peso_residuo) + 'Lt' + 'peso lordo:' + (element.kg).toFixed(2) + 'Lt' */ + '</option>');
                        } else if (element.metri_cubi != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.metri_cubi + '">Giacenza iniziale del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.metri_cubi + 'm3' /* + 'peso scaricato:' + (element.peso_residuo) + 'm3' + 'peso lordo:' + (element.kg).toFixed(2) + 'm3' */ + '</option>');
                        }
                    } else {
                        if (element.kg != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected  + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.kg + '">Carico N°' + element.numero + ' del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.kg + 'kg' /* + 'peso scaricato:' + (element.peso_residuo) + 'kg' + 'peso lordo:' + (element.kg).toFixed(2) + 'kg' */ + '</option>');      
                        } else if (element.litri != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.litri + '">Carico N°' + element.numero + ' del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.litri + 'Lt' /* + 'peso scaricato:' + (element.peso_residuo) + 'Lt' + 'peso lordo:' + (element.kg).toFixed(2) + 'Lt' */ + '</option>');
                        } else if (element.metri_cubi != null /*&& element.stato_registro == 'non_stampato'*/) {
                            var x = target.append('<option ' + selected + ' value="' + element.id + '" class="carico_scelto" data-unita_misura="'+ element.unita_misura +'" data-peso="' + element.metri_cubi + '">Carico N°' + element.numero + ' del ' + element.data_movimento + ' - ' + 'Residuo: ' + element.metri_cubi + 'm3' /* + 'peso scaricato:' + (element.peso_residuo) + 'm3' + 'peso lordo:' + (element.kg).toFixed(2) + 'm3' */ + '</option>');
                        }
                    }

                    //Se in edit vado a modificare la data e quindi ricalcolo i carichi associabili, venendo a mancare quello selezionato devo riottenere il peso perso e renderlo associabile
                    if ($('.old-carico') && $('#movimenti_carico_associati option').length == 0) {
                        getOldCarichiAssociati(element.data_movimento, element.id, element.numero);
                    }

                });
            } else {
                target.append('<option selected class="text-center" value="" disabled> - nessun carico disponibile - </option>');
            }
            manageRimanenze();
            toggleRimanenzeMagazzinoAlert();
        },
        'error': function (error) {
            console.log(error);
        }
    });
}
var route = $('#cer_id').data('route');

$('#cer_id').on('change', function () {
    var target = $('#stato_fisico_selected');
    const anagraficaId = $('input[name="anagrafica_detentore_id"]').val();
    let cerId = $('select[name="cer_id"]').val();
    
    //manageStatoFisico($(this), anagraficaId, cerId, route, target)
    getStatoFisico(anagraficaId, cerId, route, target);
});

function getStatoFisico(anagraficaId, cerId, route, target) {
    $.ajax({
        'url': route,
        'type': 'GET',
        'data': {
            'anagrafica_id': anagraficaId,
            'cer_id': cerId
        },
        'success': function (data) {
            var dataDecode = JSON.parse(data);
            target.find('option').remove();

            if (dataDecode) {
                if (dataDecode.stato_fisico != null) {
                    target.append('<option value="' + dataDecode.stato_fisico + '">' + dataDecode.stato_fisico + '</option>')
                } else {
                    target.append('<option class="text-center" value=""></option>');
                }
            } 
        },
        'error': function (error) {
            console.log(error);
        }
    });
}