$(function () {
    $('#annoMeseChart').each(function() {
        var options = {
            series: [],
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                },
                updateOptions: {
                    redrawPaths: true
                },
                fontFamily: "Manrope, sans-serif"
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 1
                },
            },
            xaxis: {
                type: 'string',
                categories: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'
                ],
            },
            legend: {
                position: 'top',
                offsetY: 30
            },
            fill: {
                opacity: 1
            },
            colors: ['#00a19a', '#91c23d', '#00663d', '#ffb247', '#ff2c32']
        };

        var tipologia = $('#tipologia_registro').val();
        var chart = new ApexCharts(document.querySelector("#annoMeseChart"), options);
        chart.render();

        triggerChart(tipologia, anno=null);

        function triggerChart(tipologia, anno) {
            if ($('#annoMeseChart').length) {
                if (!anno) {
                    anno = new Date().getFullYear();
                }
                $.ajax({
                    url: $('#annoMeseChart').data('url'),
                }).done(function (data) {console.log(data);
                    console.log();
                    if (typeof data[tipologia]?.[anno] === 'undefined') {
                        $('#annoMeseChart').hide();
                        $('#annoMeseChart').empty();
                        $('#chart-error-msg').show();
                    } else {
                        $('#annoMeseChart').show();
                        $('#annoMeseChart').empty();
                        $('#chart-error-msg').hide();

                        chart.updateSeries(
                            data[tipologia][anno].series
                        )
                    }

                });
            }
        }

        $('#select-anno-chart').on('change', function () {
            var anno = $(this).val();
            var tipologia = $('#select-tipologia-chart').val();
            triggerChart(tipologia, anno);
        })

        $('#select-tipologia-chart').on('change', function () {
            var anno = $('#select-anno-chart').val();
            var tipologia = $(this).val();
            triggerChart(tipologia, anno);
        })
    })
});
