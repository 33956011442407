require('./movimenti');

/**
 * Manage fields reserved to the destinatario
 */
$(function manageCampiRiservatidestinatario() {
    $('#respingimento_unita_misura_extra_data_kg').on('change', function() {
        if($('#respingimento_unita_misura_extra_data_kg').is(':checked')){
            $('#respingimento_quantita_extra_data').prop('disabled',false);
        }        
    });

    $('#respingimento_unita_misura_extra_data_lt').on('change', function() {
        if($('#respingimento_unita_misura_extra_data_lt').is(':checked')){
            $('#respingimento_quantita_extra_data').prop('disabled',false);
        }        
    });

    $('#respingimento_parziale_A').on('change', function() {
        if($('#respingimento_parziale_A').is(':checked')){
            $('#motivazione_respinta_extra_data').prop('disabled',false);
        }        
    });

    /*function managePesoAccettato() {
        var input_quantita = $('.quantita_parziale');
        var motivazione_respinta = $('#motivazione_respinta_extra_data')

        if($('#accettato_parziale').is(':checked')) {
            input_quantita.prop('disabled', false);
        } else {
            input_quantita.prop('disabled', true)
        }
        
        if ($('#respingimento_parziale_altro').is(':checked')) {
            motivazione_respinta.prop('disabled', false)
        } else {
            motivazione_respinta.prop('disabled', true)
        }
    }*/

    /*managePesoAccettato();

    $('input[name="accettato_extra_data"]').on('change', function() {
        managePesoAccettato();
    })*/
});

/**
 * Keeps track of wether the movimento must be closed or not
 */
$(function manageEditCaricoScaricoUpdate() {    
    const hidden_close_movimento = $('#close_movimento');
    $('#salva_movimento').on('click', function() {
        $('#respingimento_no').prop('required', false);
        hidden_close_movimento.val(0);
    });

    $('#chiudi_movimento').on('click', function() {
        if(!$('#respingimento_no').is(':checked') && !$('#respingimento_parziale').is(':checked') && !$('#respingimento_totale').is(':checked')){
            $('#respingimento_no').prop('required', true);
            hidden_close_movimento.val(1);
        } else if($('#respingimento_no').is(':checked')) {
            $('#respingimento_no').prop('required', false);
            hidden_close_movimento.val(0);
        } else if ($('#respingimento_parziale').is(':checked') && !$('#respingimento_unita_misura_extra_data_kg').is(':checked')) {
            $('#respingimento_unita_misura_extra_data_kg').prop('required', true);
            hidden_close_movimento.val(1);
        } else if ($('#respingimento_parziale').is(':checked') && $('#respingimento_unita_misura_extra_data_kg').is(':checked') || $('#respingimento_unita_misura_extra_data_lt').is(':checked')&& $('#respingimento_quantita_extra_data').val() == '') {
            $('#respingimento_quantita_extra_data').prop('required', true);
            hidden_close_movimento.val(1);
        }
    });

});